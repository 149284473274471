.ant-table-tbody > tr > td {
  /* padding: 16px 16px; */
  word-break: break-word;
  -ms-word-break: break-all;
}
/* media query to override the default ant design style that is specifically for desktop devices */
.ant-layout-header {
  padding: 0 10px;
}

@media (min-width: 768px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 16px 16px;
  }
  .ant-layout-header {
    padding: 0 50px;
  }
}

body {
  background-color: #f0f2f5;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.ant-row.ant-form-item {
  margin-bottom: 20px;
}
